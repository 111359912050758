import { isDevelopment, isMSWEnabled } from "config";
import { BrandCode } from "brandRegionConfig";
import { FixHubPermissions } from "./permissions";
import { AvailableRoutes } from "./availableRoutes";

interface PagePermission {
  /** If set, at least one of these permissions must be granted to the user */
  hasSomePermissions?: FixHubPermissions[]; //
  /** If set, all of these permissions must be granted to the user */
  hasAllPermissions?: FixHubPermissions[];
  /** Disables the page for all users */
  disabled?: boolean;
  /** If set, limits the page to the specified brands */
  availableForBrands?: BrandCode[];
}

const PaymentRequestSecurity: PagePermission = {
  hasSomePermissions: ["Fixhub.PaymentRequests"],
};

const CmsEditorSecurity: PagePermission = {
  hasSomePermissions: [
    "CmsKit.BlogPosts.Create",
    "CmsKit.BlogPosts.Update",
    "CmsKit.BlogPosts.Delete",
    "CmsKit.BlogPosts.Publish",
  ],
};

const NewsEventSecurity: PagePermission = {
  hasSomePermissions: ["NewsEvents.Articles", "NewsEvents.Categories"],
};

/**
 * Map of routes to their required permissions.
 * Used by PagePermissionChecker component to determine if a user has access to a page.
 */
export const routeAccessMap = new Map<AvailableRoutes, PagePermission>();

routeAccessMap.set("/[region]/payment-request", PaymentRequestSecurity);
routeAccessMap.set("/[region]/payment-request/add", PaymentRequestSecurity);
routeAccessMap.set(
  "/[region]/payment-request/[paymentRequestId]",
  PaymentRequestSecurity
);
routeAccessMap.set(
  "/[region]/payment-request/[paymentRequestId]/edit",
  PaymentRequestSecurity
);
routeAccessMap.set("/[region]/test-page", { disabled: !isDevelopment });
routeAccessMap.set("/[region]/estimate-request", {
  hasSomePermissions: ["Fixhub.EstimateRequests"],
});
routeAccessMap.set("/[region]/estimate-request/[id]", {
  hasSomePermissions: ["Fixhub.EstimateRequests"],
});
routeAccessMap.set("/[region]/reports/shop-performance", {
  disabled: !isMSWEnabled,
});
routeAccessMap.set("/[region]/reports/shop-performance/[id]", {
  disabled: !isMSWEnabled,
});
routeAccessMap.set("/[region]/reports/waiting-list", {
  disabled: !isMSWEnabled,
});
routeAccessMap.set("/[region]/reports/vehicle-location", {
  disabled: !isMSWEnabled,
});
routeAccessMap.set("/[region]/reports/vehicle-location/[id]", {
  disabled: !isMSWEnabled,
});
routeAccessMap.set("/[region]/cms-editor", CmsEditorSecurity);
routeAccessMap.set("/[region]/cms-editor/[id]", CmsEditorSecurity);
routeAccessMap.set("/[region]/cms-editor/create", CmsEditorSecurity);
routeAccessMap.set("/[region]/shop-hours-management", {
  hasSomePermissions: ["SharedEntities.ShopHours"],
});
routeAccessMap.set("/[region]/shop-hours-management/admin", {
  hasAllPermissions: [
    "SharedEntities.ShopHours.Edit",
    "SharedEntities.ShopHours.BrandlessAdmin",
  ],
});
// Publication Management
routeAccessMap.set(
  "/[region]/manage/publications/[[...publicationTab]]",
  NewsEventSecurity
);
routeAccessMap.set("/[region]/manage/publications/articles/create", {
  hasSomePermissions: ["NewsEvents.Articles.Create"],
});
routeAccessMap.set("/[region]/manage/publications/articles/[id]", {
  hasSomePermissions: ["NewsEvents.Articles.Edit"],
});
routeAccessMap.set("/[region]/manage/publications/categories/create", {
  hasSomePermissions: ["NewsEvents.Categories.Create"],
});
routeAccessMap.set("/[region]/manage/publications/categories/[id]", {
  hasSomePermissions: ["NewsEvents.Categories.Edit"],
});
routeAccessMap.set("/[region]/manage/publications/labels/create", {
  hasSomePermissions: ["NewsEvents.Categories.Create"],
});
routeAccessMap.set("/[region]/manage/publications/labels/[id]", {
  hasSomePermissions: ["NewsEvents.Categories.Edit"],
});
// Advertisement Banners
routeAccessMap.set("/[region]/manage/advertisement-banner", {
  hasSomePermissions: ["Fixhub.AdvertisementBanners"],
});
routeAccessMap.set("/[region]/manage/advertisement-banner/create", {
  hasSomePermissions: ["Fixhub.AdvertisementBanners.Create"],
});
routeAccessMap.set("/[region]/manage/advertisement-banner/[id]", {
  hasSomePermissions: ["Fixhub.AdvertisementBanners.Edit"],
});
routeAccessMap.set("/[region]/novus-products", {
  availableForBrands: ["novusglass"],
});
routeAccessMap.set("/[region]/shop-sales/create/[[...shopId]]", {
  hasAllPermissions: [
    "Sales.SaleShop.CanUploadSales",
    "Sales.SaleShop.CanGetShopSalesAdjusments",
    "Sales.ShopsSaleAdjustments.SalesSubmissionUploads",
  ],
});
routeAccessMap.set("/[region]/dms/library", {
  hasSomePermissions: [
    "Fixhub.DocLibraries",
    "Fixhub.DocLibraries.Create",
    "Fixhub.DocLibraries.Edit",
    "Fixhub.DocLibraries.Delete",
  ],
});
