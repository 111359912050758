import { useState } from "react";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LibraryFormValues } from "features/DMS/entities";
import { useToaster } from "core/hooks/useToaster";
import {
  useDMSFormErrorHandler,
  useManageableLibraries,
} from "features/DMS/hooks";
import { usePostJsonToCentrix } from "core/hooks/usePostJsonToCentrix";
import { LibraryForm, defaultValues } from "./LibraryForm";
import { convertLibraryFormValuesToDocLibraryCreateUpdateDto } from "./convertLibraryFormValuesToDocLibraryCreateUpdateDto";

export interface LibraryAddProps {
  cancelOnClick: () => void;
}

export function DmsLibraryAdd({ cancelOnClick }: LibraryAddProps) {
  const formMethods = useForm<LibraryFormValues>({
    defaultValues,
    mode: "onChange",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { handleSubmit } = formMethods;
  const { mutate } = useManageableLibraries();
  // const apiPost = useAPIPostJSON();
  const apiPost = usePostJsonToCentrix();
  const { successToast, errorToast } = useToaster();
  const { t } = useTranslation("DocumentManagementSystem");
  const { errorHandler, scrollToRef } = useDMSFormErrorHandler(
    formMethods,
    "LibraryForm"
  );
  async function handleFormSubmit(data: LibraryFormValues) {
    setIsSubmitting(true);
    try {
      const body = convertLibraryFormValuesToDocLibraryCreateUpdateDto(data);
      await apiPost({
        path: "/api/app/doc-library",
        body,
      });
      await mutate();
      successToast(t("Next:DocumentManagementSystem:LibraryAdd.POSTSuccess"));
      cancelOnClick();
    } catch {
      setIsSubmitting(false);
      errorToast(t("Next:DocumentManagementSystem:LibraryAdd.POSTError"));
    }
  }

  function clearOnClick() {
    formMethods.reset(defaultValues);
  }

  return (
    <>
      <Typography
        variant="h3"
        textAlign="center"
        color="primary.main"
        my={3}
        ref={scrollToRef}
      >
        {t("Next:DocumentManagementSystem:LibraryAdd.title")}
      </Typography>
      <LibraryForm
        isSubmitting={isSubmitting}
        formMethods={formMethods}
        onSubmit={handleSubmit(handleFormSubmit, errorHandler)}
        cancelOnClick={cancelOnClick}
        clearOnClick={clearOnClick}
      />
    </>
  );
}
