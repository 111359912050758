import { BodyJSONData } from "core/entities";
import { APIFetch } from "core/utils/APIFetch";
import { validateResponse } from "core/utils/validateResponse";
import { useAPIHeaders } from "./useAPIHeaders";

/**
 * @deprecated This function is deprecated and will be removed in future versions.
 * Please use the `useCentrixFetch` instead.
 */
export function useAPIPutJSON() {
  const headers = useAPIHeaders();
  return async <T = any>(path: string, data: BodyJSONData): Promise<T> => {
    const res = await APIFetch(path, {
      method: "PUT",
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    await validateResponse(res);
    const jsonData = await res.json();
    return jsonData;
  };
}
