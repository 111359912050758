import { MouseEvent, useState, ReactNode } from "react";
import {
  CurrentWeatherDisplay,
  IMultipleWeatherForecastDto,
  WeatherDayDisplay,
  WeatherWidgetViewComponentProps,
} from "features/Weather/entities";
import { useTranslation } from "react-i18next";
import { useBreakpoints } from "core/hooks/useBreakpoints";
import { useFormatDate } from "core/hooks/useFormatDate";
import { useFormatTemperature } from "core/hooks/useFormatTemperature";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import { usePreferredTemperatureUnit } from "core/hooks/usePreferredTemperatureUnit";
import { GridAreaContainer } from "core/components/GridAreaContainer";
import { Loading } from "core/components/Loading";
import { ErrorComponent } from "core/components/ErrorComponent";
import { useWeatherForecast } from "../../hooks";
import { MobileWeatherWidget } from "./MobileWeatherWidget";
import { TabletWeatherWidget } from "./TabletWeatherWidget";
import { DesktopWeatherWidget } from "./DesktopWeatherWidget";

export interface WeatherWidgetContentProps {
  weatherData: IMultipleWeatherForecastDto[];
  tempUnit: "C" | "F";
}

function WeatherWidgetContent({
  weatherData,
  tempUnit,
}: WeatherWidgetContentProps) {
  const { isMobile, isTablet } = useBreakpoints();
  const [locationIndex, setLocationIndex] = useState(0);
  const formatDate = useFormatDate({
    day: "numeric",
    weekday: "long",
    month: "long",
    timeZone: "UTC",
  });
  const formatDateNoWeekDay = useFormatDate({
    day: "numeric",
    month: "long",
    timeZone: "UTC",
  });
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Weather:WeatherWidget",
  });
  const formatTemp = useFormatTemperature({ maximumFractionDigits: 0 });

  if (!weatherData) {
    return <Typography color="error.main">{t("noWeatherData")}</Typography>;
  }

  if (!weatherData.length) {
    return <Typography color="error.main">{t("noLocationData")}</Typography>;
  }

  const currentLocationEntry = weatherData[locationIndex];
  const location = currentLocationEntry?.location;
  const weatherDays = currentLocationEntry?.weatherDays;

  const weatherToday = weatherDays?.[0];
  const weatherCurrent = weatherToday?.weatherCurrent[0];
  const invalidDataText = t("invalidData");
  if (!currentLocationEntry || !weatherToday || !weatherCurrent || !location) {
    // eslint-disable-next-line no-console
    console.error({
      currentLocationEntry,
      weatherToday,
      weatherCurrent,
      location,
    });
    return (
      <Typography color="error" variant="h4">
        {invalidDataText}
      </Typography>
    );
  }
  const showControls = weatherData.length > 1;
  const nextLocationDisabled = locationIndex + 1 >= weatherData.length;
  const previousLocationDisabled = locationIndex === 0;
  const formattedToday = `${t("today")}, ${formatDateNoWeekDay(
    weatherToday.date
  )}`;
  const currentWeatherFormatted: CurrentWeatherDisplay = {
    // date: formatDate(weatherToday.date),
    date: formattedToday,
    tempCurrent: formatTemp(
      tempUnit === "C" ? weatherCurrent.temp_c : weatherCurrent.temp_f
    ),
    tempLow: formatTemp(
      tempUnit === "C"
        ? weatherToday.weatherDay.mintemp_c
        : weatherToday.weatherDay.mintemp_f
    ),
    tempHigh: formatTemp(
      tempUnit === "C"
        ? weatherToday.weatherDay.maxtemp_c
        : weatherToday.weatherDay.maxtemp_f
    ),
    code: weatherCurrent.condition.code,
  };

  const weatherDaysFormatted: WeatherDayDisplay[] = weatherDays
    .slice(1)
    .map((day) => ({
      id: day.date,
      conditionText: day.weatherDay.condition.text,
      conditionCode: day.weatherDay.condition.code,
      date: formatDate(day.date),
      tempHigh: formatTemp(
        tempUnit === "C" ? day.weatherDay.maxtemp_c : day.weatherDay.maxtemp_f
      ),
      tempLow: formatTemp(
        tempUnit === "C" ? day.weatherDay.mintemp_c : day.weatherDay.mintemp_f
      ),
    }));

  function nextLocation() {
    setLocationIndex((currentIndex) => {
      if (currentIndex + 1 >= weatherData.length) {
        return currentIndex;
      }
      return currentIndex + 1;
    });
  }
  function previousLocation() {
    setLocationIndex((currentIndex) => {
      if (currentIndex === 0) {
        return currentIndex;
      }
      return currentIndex - 1;
    });
  }

  const weatherWidgetContentChildProps: WeatherWidgetViewComponentProps = {
    externalLink: `https://www.weatherapi.com/weather/default.aspx?q=${location.lat},${location.lon}`,
    location,
    weatherDays: weatherDaysFormatted,
    weatherCurrent: currentWeatherFormatted,
    nextLocation,
    previousLocation,
    showControls,
    nextLocationDisabled,
    previousLocationDisabled,
  };

  if (isMobile) {
    return <MobileWeatherWidget {...weatherWidgetContentChildProps} />;
  }

  if (isTablet) {
    return <TabletWeatherWidget {...weatherWidgetContentChildProps} />;
  }

  return <DesktopWeatherWidget {...weatherWidgetContentChildProps} />;
}

function WeatherWidgetWrapper({ children }: { children: ReactNode }) {
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Weather:WeatherWidget",
  });
  const gridAreaContainerTitle = t("gridAreaContainerTitle");
  const { value: tempUnit, setValue: setTempUnit } =
    usePreferredTemperatureUnit();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (unit?: "C" | "F") => {
    if (unit === "C" || unit === "F") {
      setTempUnit(unit);
    }
    setAnchorEl(null);
  };
  return (
    <GridAreaContainer
      disableBottomSlot
      gridArea="weather"
      title={gridAreaContainerTitle}
      RightSlot={
        <>
          <IconButton onClick={handleClick}>
            <MoreHoriz />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem
              disabled={tempUnit === "C"}
              onClick={() => handleClose("C")}
            >
              {t("celsius")}
            </MenuItem>
            <MenuItem
              disabled={tempUnit === "F"}
              onClick={() => handleClose("F")}
            >
              {t("fahrenheit")}
            </MenuItem>
          </Menu>
        </>
      }
    >
      {children}
    </GridAreaContainer>
  );
}

export function WeatherWidget() {
  const { data, isLoading, isError } = useWeatherForecast();
  const { value: tempUnit } = usePreferredTemperatureUnit();
  if (isError) {
    return (
      <WeatherWidgetWrapper>
        <ErrorComponent apiError={isError} />
      </WeatherWidgetWrapper>
    );
  }

  if (!data || !Array.isArray(data?.items) || isLoading) {
    return (
      <WeatherWidgetWrapper>
        <Loading textDisabled />
      </WeatherWidgetWrapper>
    );
  }
  return (
    <WeatherWidgetWrapper>
      <WeatherWidgetContent weatherData={data.items} tempUnit={tempUnit} />
    </WeatherWidgetWrapper>
  );
}
