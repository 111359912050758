import { useRouter } from "next/router";
import { useCallback, useRef } from "react";
import { useRegion } from "./useRegion";

const defaultOptions: Intl.DateTimeFormatOptions = {
  dateStyle: "long",
} as const;

/**
 * Returns a memoized date formatter function that formats dates according to the specified options.
 *
 * @warning Only the initial options will be used. Subsequent changes to the options object will not be reflected in the formatter function.
 */
export function useFormatDate(
  options: Intl.DateTimeFormatOptions = defaultOptions
): (date: string | Date) => string {
  const { locale, defaultLocale } = useRouter();
  const region = useRegion();
  const localeString = locale ?? defaultLocale ?? "en";
  const formatterLocale = `${localeString}-${region}`;
  const optionsRef = useRef(options);

  const formatter = useCallback(
    (dateArg: string | Date) => {
      const dateFormatter = new Intl.DateTimeFormat(
        formatterLocale,
        optionsRef.current
      ).format;
      const date = dateArg instanceof Date ? dateArg : new Date(dateArg);
      try {
        return dateFormatter(date);
      } catch {
        return "error";
      }
    },
    [formatterLocale]
  );
  return formatter;
}
