import Fade from "@mui/material/Fade";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import StarIcon from "@mui/icons-material/Star";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomLink } from "core/components/CustomLink";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { MyLinksIcon } from "./MyLinksIcon";
import { getMyLinksHref } from "../utils";
import { useFavorites } from "../hooks";

export function MyLinksMenu() {
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:MyLinks:MyLinksMenu",
  });
  const { data } = useFavorites();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const favorites =
    data && Array.isArray(data?.items) ? data.items.slice(0, 6) : [];
  const remainingFavoritesCount = data?.totalCount
    ? data.totalCount - favorites.length
    : 0;
  function closeMenu() {
    setAnchorEl(null);
  }

  return (
    <>
      <Button
        color="inherit"
        variant="text"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {t("buttonText")}
        <ArrowDropDownIcon />
      </Button>
      <Menu
        sx={{ maxWidth: "75vw" }}
        TransitionComponent={Fade}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <Tabs variant="standard" value="favorite" sx={{ px: 1, pb: 1 }}>
          <Tab label={<StarIcon fontSize="small" />} value="favorite" />
          {/* <Tab label={<LinkIcon />} value="quick" /> */}
          {/* <Tab label={<RestoreIcon />} value="frequent" /> */}
        </Tabs>
        {favorites.length === 0 && (
          <MenuItem disabled>{t("noFavorites")}</MenuItem>
        )}
        {favorites.map(({ id, itemId, itemType, title }) => (
          <MenuItem
            key={id}
            component={CustomLink}
            href={getMyLinksHref(itemType, itemId)}
            onClick={closeMenu}
          >
            <ListItemIcon>
              <MyLinksIcon itemType={itemType} />
            </ListItemIcon>
            <Typography noWrap>{title ?? `${itemType}:${itemId}`}</Typography>
          </MenuItem>
        ))}
        <Divider />
        {remainingFavoritesCount > 0 ? (
          <MenuItem component={CustomLink} href="/my-links" onClick={closeMenu}>
            {t("goToFavoritesPage", { count: remainingFavoritesCount })}
          </MenuItem>
        ) : (
          <MenuItem component={CustomLink} href="/my-links" onClick={closeMenu}>
            {t("goToFavoritesPage")}
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
