import { usePreferredLocale } from "core/hooks/usePreferredLocale";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

function useLocaleRedirect() {
  const isDBLocaleFirstMount = useRef(true);
  const isStorageLocaleFirstMount = useRef(true);
  const { locale, pathname, query, asPath, replace } = useRouter();
  const { localStorageValue: storageLocale, databaseValue: databaseLocale } =
    usePreferredLocale();

  useEffect(() => {
    if (!isStorageLocaleFirstMount.current || !isDBLocaleFirstMount.current) {
      return;
    }
    if (/oidc/.test(asPath)) {
      return;
    }
    isStorageLocaleFirstMount.current = false;
    if (storageLocale === locale) {
      return;
    }
    if (typeof storageLocale !== "string") {
      return;
    }
    replace({ pathname, query }, asPath, { locale: storageLocale });
  }, [locale, storageLocale, replace, pathname, query, asPath]);

  useEffect(() => {
    if (!isDBLocaleFirstMount.current) {
      return;
    }
    if (/oidc/.test(asPath)) {
      return;
    }
    if (!databaseLocale) {
      return;
    }
    isDBLocaleFirstMount.current = false;
    if (databaseLocale === locale) {
      return;
    }
    if (typeof databaseLocale !== "string") {
      return;
    }
    replace({ pathname, query }, asPath, { locale: databaseLocale });
  }, [locale, databaseLocale, replace, pathname, query, asPath]);
}

/**
 * Empty component that syncs the i18n language to the current router locale
 * @returns null
 */
export function RouterLocale() {
  const { locale } = useRouter();
  const { i18n } = useTranslation();
  useLocaleRedirect();
  useEffect(() => {
    if (!i18n || !locale) {
      return;
    }
    if (process.env.NEXT_PUBLIC_LOCALE_CI_MODE === "true") {
      i18n.changeLanguage("cimode");
      return;
    }
    i18n.changeLanguage(locale);
  }, [i18n, locale]);
  return null;
}
