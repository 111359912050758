import { ReqPath } from "core/entities";
import { useCallback } from "react";
import { useAPIFetch } from "./useAPIFetch";

/**
 * @deprecated This function is deprecated and will be removed in future versions.
 * Please use the `useCentrixFetch` instead.
 */
export function useAPIDelete() {
  const apiFetch = useAPIFetch();
  return useCallback(
    (reqPath: ReqPath) => apiFetch(reqPath, { method: "DELETE" }),
    [apiFetch]
  );
}
