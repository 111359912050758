import Typography from "@mui/material/Typography";
import { useToaster } from "core/hooks/useToaster";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { usePostJsonToCentrix } from "core/hooks/usePostJsonToCentrix";
import { CategoryFormValues } from "../entities";
import { useCategories, useDMSFormErrorHandler } from "../hooks";
import { DmsCategoryForm, defaultValues } from "./DmsCategoryForm";

interface SubCategoryAddProps {
  cancelOnClick: () => void;
  parentId: string;
  docLibraryId: string;
  canManageLibraryContents: boolean;
}

export function SubCategoryAdd({
  cancelOnClick,
  parentId,
  docLibraryId,
  canManageLibraryContents,
}: SubCategoryAddProps) {
  const { t } = useTranslation("DocumentManagementSystem");

  const formMethods = useForm<CategoryFormValues>({
    defaultValues,
    mode: "onChange",
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods;
  const { mutate } = useCategories({
    parentId,
    mode: "manage",
    canManageLibraryContents,
  });
  const apiPost = usePostJsonToCentrix();
  const { successToast, errorToast } = useToaster();
  const { errorHandler } = useDMSFormErrorHandler(formMethods, "CategoryForm");

  async function handleFormSubmit({
    translations: translationsObject,
  }: CategoryFormValues) {
    try {
      const translations = Object.values(translationsObject).filter(
        (entry): entry is typeof entry & { name: string } =>
          typeof entry.name === "string"
      );
      await apiPost({
        path: "/api/app/doc-category",
        body: {
          parentId,
          docLibraryId,
          translations,
        },
      });
      await mutate();
      successToast(
        t("Next:DocumentManagementSystem:SubCategoryAdd.POSTSuccess")
      );
      cancelOnClick();
    } catch {
      errorToast(t("Next:DocumentManagementSystem:SubCategoryAdd.POSTError"));
    }
  }

  function clearOnClick() {
    formMethods.reset(defaultValues);
  }

  return (
    <>
      <Typography variant="h3" textAlign="center" color="primary.main" my={3}>
        {t("Next:DocumentManagementSystem:SubCategoryAdd.title")}
      </Typography>
      <DmsCategoryForm
        formMethods={formMethods}
        onSubmit={handleSubmit(handleFormSubmit, errorHandler)}
        cancelOnClick={cancelOnClick}
        clearOnClick={clearOnClick}
        mode="add"
        formType="SubCategoryForm"
        isSubmitting={isSubmitting}
      />
    </>
  );
}
