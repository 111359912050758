import { useAPIDelete } from "core/hooks/useAPIDelete";
import { useAPIPostJSON } from "core/hooks/useAPIPostJSON";
import { GenericAPIData, IFavoriteDto, ItemType } from "core/entities";
import { uuid } from "core/utils/uuid";
import { useFavorites } from "./useFavorites";

export function useFavorite(
  itemType: ItemType,
  itemId: string,
  title?: string
) {
  const apiPost = useAPIPostJSON();
  const apiDelete = useAPIDelete();

  const { data, isError, isLoading, mutate } = useFavorites();

  const favoriteEntry = data?.items?.find(
    (entry) => entry.itemId === itemId && entry.itemType === itemType
  );

  async function addToFavorites() {
    if (favoriteEntry) {
      throw new Error("item already favorited");
    }
    function addInCache(
      currentCache: GenericAPIData<IFavoriteDto> | undefined,
      entry: IFavoriteDto
    ) {
      if (!currentCache || !Array.isArray(currentCache?.items)) {
        return { items: [entry], totalCount: 1 };
      }
      return {
        items: [...currentCache.items, entry],
        totalCount: currentCache.totalCount + 1,
      };
    }
    await mutate(
      async (currentData) => {
        const newFavorite = await apiPost("/api/app/favorites", {
          itemType,
          itemId,
        });
        return addInCache(currentData, { ...newFavorite, title });
      },
      {
        optimisticData: (currentCache) =>
          addInCache(currentCache, { id: uuid(), title, itemId, itemType }),
      }
    );
  }

  async function removeFromFavorites() {
    const idToDelete = favoriteEntry?.id;
    if (!idToDelete) {
      throw new Error("no id found");
    }

    function filterFavorite(
      dataToFilter: GenericAPIData<IFavoriteDto> | undefined
    ) {
      if (!dataToFilter || !Array.isArray(dataToFilter.items)) {
        return { items: [], totalCount: 0 };
      }
      return {
        items: dataToFilter.items.filter((item) => item.id !== idToDelete),
        totalCount: dataToFilter.totalCount - 1,
      };
    }
    await mutate(
      async (currentData) => {
        await apiDelete(`/api/app/favorites/${idToDelete}`);
        return filterFavorite(currentData);
      },
      { optimisticData: (cur) => filterFavorite(cur) }
    );
  }

  return {
    isLoading,
    isError,
    isFavorite: !!favoriteEntry,
    addToFavorites,
    removeFromFavorites,
  };
}
