import { GenericAPIData, IFavoriteDto } from "core/entities";
import { useAPI } from "core/hooks/useAPI";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

export function useFavorites() {
  const { locale } = useRouter();
  const { data, isError, isLoading, mutate } = useAPI<
    GenericAPIData<IFavoriteDto>
  >({ path: "/api/app/favorites/current-user-favorites", params: { locale } });
  const { t: tPage } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:PageTitle",
  });
  const { t: tExtenso } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:ExtensoReportPage.title",
  });

  const processedItems = data?.items.map((item) => {
    if (item.itemType === "Extenso") {
      const code = item.itemId.split("/").at(1);
      if (!code) {
        return item;
      }
      const title = tExtenso(code);
      return { ...item, title };
    }
    if (item.itemType === "Page") {
      return { ...item, title: tPage(item.itemId) };
    }
    if (!item.title) {
      return { ...item, title: `${item.itemType}:${item.itemId}` };
    }
    return item;
  });

  return {
    data: data ? { ...data, items: processedItems } : undefined,
    isError,
    isLoading,
    mutate,
  };
}
