import { useMemo } from "react";
import { useCentrixApi } from "core/hooks/useCentrixApi";
import { useDmsCategoryLibraryProcessor } from "./useDmsCategoryLibraryProcessor";

/**
 * Returns an array of libraries with the current locale translations surfaced.
 * If the user has DocLibrary Crud permissions the endpoint will return all libraries
 * Otherwise it will return the libraries the user has permission to manage
 */
export function useManageableLibraries(shouldFetch: boolean = true) {
  const processLibrary = useDmsCategoryLibraryProcessor();
  const { data, isLoading, isError, mutate } = useCentrixApi({
    path: "/api/app/doc-library",
    parameters: {
      query: {
        MaxResultCount: 1000,
      },
    },
    shouldFetch,
  });

  const libraries = useMemo(
    () => data?.items?.map((lib) => processLibrary(lib)) ?? [],
    [data, processLibrary]
  );

  return {
    data,
    libraries,
    isLoading,
    isError,
    mutate,
  };
}
