import { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import { useTranslation } from "react-i18next";
import { useFavorites } from "features/MyLinks/hooks";
import { getMyLinksHref } from "features/MyLinks/utils";
import ListItemIcon from "@mui/material/ListItemIcon";
import { MyLinksIcon } from "features/MyLinks/components/MyLinksIcon";
import { CustomLink } from "core/components/CustomLink";
import { ExpandButton } from "core/components/MobileNav/ExpandButton";

export function MobileMyLinksListItem() {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:MobileMyLinksListItem",
  });
  const { data } = useFavorites();
  const favorites = data && Array.isArray(data?.items) ? data.items : [];
  return (
    <>
      <ListItem
        secondaryAction={
          <ExpandButton
            open={open}
            onClick={() => {
              setOpen((currentOpenState) => !currentOpenState);
            }}
          />
        }
      >
        <ListItemText
          primary={t("listItemText")}
          primaryTypographyProps={{ fontWeight: "bold" }}
        />
      </ListItem>
      <Collapse in={open}>
        <List dense>
          {favorites.length === 0 ? (
            <ListItem>
              <ListItemText primary={t("noFavorites")} />
            </ListItem>
          ) : (
            favorites.map(({ id, itemId, itemType, title }) => (
              <ListItem key={id} disablePadding>
                <ListItemButton
                  sx={{ pl: 4 }}
                  component={CustomLink}
                  href={getMyLinksHref(itemType, itemId)}
                >
                  <ListItemIcon>
                    <MyLinksIcon itemType={itemType} />
                  </ListItemIcon>
                  <ListItemText primary={title ?? `${itemType}:${itemId}`} />
                </ListItemButton>
              </ListItem>
            ))
          )}
          <Divider sx={{ my: 1, mx: 5 }} />
          <ListItem disablePadding>
            <ListItemButton href="/my-links" component={CustomLink}>
              <ListItemText
                primary={t("goToMyLinksPage")}
                primaryTypographyProps={{
                  textAlign: "center",
                }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Collapse>
    </>
  );
}
