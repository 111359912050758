import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useToaster } from "core/hooks/useToaster";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { arrayToObject } from "core/utils/arrayToObject";
import { useCentrixApi } from "core/hooks/useCentrixApi";
import { usePutJsonToCentrix } from "core/hooks/usePutJsonToCentrix";
import { DmsCategoryForm, defaultValues } from "./DmsCategoryForm";
import { useCategories, useDMSFormErrorHandler } from "../hooks";
import { ManagePaper } from "./ManagePaper";
import { CategoryFormDetailsSkeleton } from "./CategoryFormDetailsSkeleton";
import { CategoryFormValues } from "../entities";

interface SubCategoryEditProps {
  cancelOnClick: () => void;
  parentId: string;
  docLibraryId: string;
  id: string;
  canManageLibraryContents: boolean;
}

export function SubCategoryEdit({
  cancelOnClick,
  parentId,
  docLibraryId,
  id,
  canManageLibraryContents,
}: SubCategoryEditProps) {
  const formMethods = useForm<CategoryFormValues>({
    defaultValues,
    mode: "onChange",
  });
  const {
    formState: { isSubmitting },
  } = formMethods;
  const { t } = useTranslation("DocumentManagementSystem");
  const apiPut = usePutJsonToCentrix();
  const { successToast, errorToast } = useToaster();
  const { mutate: mutateParent } = useCategories({
    parentId,
    mode: "manage",
    canManageLibraryContents,
  });

  const { errorHandler } = useDMSFormErrorHandler(formMethods, "CategoryForm");
  const { isLoading, isError, data, mutate } = useCentrixApi({
    path: "/api/app/doc-category/{id}",
    parameters: { path: { id } },
  });

  useEffect(() => {
    if (!isLoading && !isError && data) {
      const { translations: translationsArray, ...rest } =
        structuredClone(data);
      if (!Array.isArray(translationsArray)) {
        return;
      }
      const translations = arrayToObject(translationsArray, "transKey");
      formMethods.reset({ translations, ...rest });
    }
  }, [data, isLoading, isError, formMethods]);

  const { handleSubmit } = formMethods;

  async function onFormSubmit({
    translations: translationsObject,
  }: CategoryFormValues) {
    try {
      const translations = Object.values(translationsObject).filter(
        (entry): entry is typeof entry & { name: string } =>
          typeof entry.name === "string"
      );
      const updatedSubCategory = await apiPut({
        path: "/api/app/doc-category/{id}",
        parameters: { path: { id } },
        body: {
          docLibraryId,
          parentId,
          translations,
        },
      });
      mutate(updatedSubCategory);
      mutateParent((categories) => {
        const updatedItems =
          categories?.items?.map((category) =>
            category.id === updatedSubCategory.id
              ? updatedSubCategory
              : category
          ) || [];
        return { items: updatedItems, totalCount: categories?.totalCount || 0 };
      });
      successToast(
        t("Next:DocumentManagementSystem:SubCategoryEdit.PUTSuccess")
      );
      cancelOnClick();
    } catch {
      errorToast(t("Next:DocumentManagementSystem:SubCategoryEdit.PUTError"));
    }
  }

  const onSubmit = handleSubmit(onFormSubmit, errorHandler);

  return (
    <>
      <Typography variant="h3" textAlign="center" color="primary.main" my={3}>
        {t("Next:DocumentManagementSystem:SubCategoryEdit.title")}
      </Typography>
      {isLoading ? (
        <ManagePaper
          sectionTitle={t(
            "Next:DocumentManagementSystem:CategoryForm.detailsTitle"
          )}
        >
          <CategoryFormDetailsSkeleton />
        </ManagePaper>
      ) : (
        <DmsCategoryForm
          cancelOnClick={cancelOnClick}
          onSubmit={onSubmit}
          formMethods={formMethods}
          mode="edit"
          formType="SubCategoryForm"
          isSubmitting={isSubmitting}
        />
      )}
    </>
  );
}
